@import '~react-toastify/dist/ReactToastify.css';
@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://cdn.syncfusion.com/ej2/bootstrap4.css");

@import './colors.css';

.ow_orders_mtr {
    margin-top: 1rem;
}

.ow_orders_mtr_half {
    margin-top: 0.5rem;
}

.ow_orders_mbr_half {
    margin-bottom: 0.5rem;
}

.ow_orders_items_tab_bt {
    border-top: 1px solid #dee2e6;
    padding-top: 0.5rem;
}

.ow_order_coi {
    cursor: pointer !important;
    font-size: 1.5rem !important;
    color: var(--busy--color) !important;
    float: right !important;
}

.ow_order_ac {
    color: var(--primary--color);
    margin-left: 5px;
    font-size: 0.7rem;
}

.ow_orders_center_date {
    text-align: center;
}

.ow_orders_received_icon {
    color: var(--pending--color)
}

.ow_orders_approved_icon {
    color: var(--approved--color)
}

.ow_orders_rejected_icon {
    color: var(--declined--color)
}

.ow_orders_order_details {
    font-size: 0.8rem;
}

.ow_order_delete_time {
    color: var(--danger);
    cursor: pointer;
}

.ow_orders_input_time {
    border: 1px solid #ced4da;
    color: #495057;
    border-radius: 0.25rem;
}

.ow_orders_times {
    font-size: 0.9rem;
}

.ow_orders_halfbold {
    font-weight: 500;
}